import React, {useEffect, useState, useGlobal} from "reactn";
import {useParams} from 'react-router-dom'
import {Table, Space, Button, Popconfirm} from 'antd';
import api from '../service/index'
import {Link} from 'react-router-dom';
import qs from 'qs';

import {
    EditOutlined,
    UserOutlined,
    DeleteOutlined,
    PlusOutlined,
    CheckOutlined,
    CloseOutlined,
    LeftOutlined,
    Loading3QuartersOutlined,
    FileExcelOutlined
} from "@ant-design/icons";

const Activity2 = (props) => {
    let params = useParams()
    let query = []
    let activity = params.id !== "add" ? params.id : false;
    query["category"] = activity;
    let [data, setData] = useState([]);
    let [totalCount, setTotalCount] = useState(0)
    let [selectedRows, setSelectedRows] = useState([])
    let [modules] = useGlobal("modules");
    let [languagesCms] = useGlobal("languagesCms"); 
    let [langCms] = useGlobal("langCms");
    let [langCode] = useGlobal("langCode");
    let [loading, setLoading] = useState(false)
    let [isSmall, ] = useGlobal('isSmall')

    let path = props.location.pathname.split('/')[1];
    let module
    if (modules !== null) {
        [module] = modules.filter(el => el._id === path);
    }

    let handleTableChange = async (page, filters, sorter) => {
        get(page, filters, sorter);
    }

    let get = async (page, filters, sorter) => {
        if (page) {
            page = {page: page.current, pageSize: page.pageSize}
        } else {
            page = {page: 1, pageSize: 10};
        }
        let shortString = 'order';

        if (sorter) {
            if (Object.keys(sorter).length) {
                shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field
            }
        }

        let _params = {sort: shortString, ...page, query};

        let restData = await api.get(`/rest/activity2?${qs.stringify(_params)}`, {_params}).then(({data}) => {
            if(data && data.result){
            setTotalCount(data.result.total);
            setLoading(false)
            return data.result.rows.map((item, key) => {
                if (item.active)
                    item.active = <CheckOutlined/>;
                else
                    item.active = <CloseOutlined/>;
                if (!item.hasOwnProperty('groups')) {
                    item.groups = []
                }
                item.key = key;
                return item;
            })}
        });
        if (restData) {
            restData.forEach((x, i) => {
                restData[i].count = x.attendees.length;
            })
        }
        setData(restData);

    }

    useEffect(() => {
        if (modules) {
            setLoading(true)
            get();
        }
    }, [])

    let rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
        },
        onSelect: (record, selected, selectedRows) => {
            setSelectedRows(selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            setSelectedRows(selectedRows);
        },
    };


    let deleteRow = async (item_id) => {
        api.delete(`/rest/activity2/${item_id}`, ({data}) => {
        });
        let newData = data.filter(el => el._id !== item_id);
        setData(newData);
    }
    let deleteSelectedRows = async () => {
        selectedRows.forEach(item => {
            api.delete(`/rest/activity2/${item._id}`, ({data}) => {
            });
            let newData = data.filter(el => el._id !== item._id);
            setData(newData);
            get();
        })
    }

    let getReport = async (record) => {
        let lang=langCode ? langCode : langCms[0].code;
        let result = await api.get(`/rest/reports/activity2?id=${record._id}&lang=${lang}`)
        const file = new Blob(["\ufeff", result.data]);
        let _url = window.URL.createObjectURL(file);
        let a = document.createElement('a');
        a.href = _url;
        a.download = `${record.langs[0].title.replace(/ /g, '')}_report.xls`;
        document.body.appendChild(a);
        a.click();
        a.remove();
    }


    let columns = [
        {
            title: languagesCms.TITLE,
            dataIndex: 'langs',
            key: 'langs',
            render: (langs) => langs.map((l, i) => <div key={i} style={{marginBottom: 5}}>{l.title} - {l.lang}</div>)
        },
        {
            title: languagesCms.QUOTA ,
            dataIndex: 'quota',
            key: 'quota',
            sorter: (a, b) => a.quota - b.quota,
            sortDirections: ['descend', 'ascend']
        },
        {
            title: languagesCms.COUNT_PARTICIPANTS ,
            dataIndex: 'count',
            key: 'count',
            sorter: (a, b) => a.count - b.count,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: languagesCms.ACTIVE ,
            dataIndex: 'active',
            key: 'active',
            sorter: (a, b) => a.active - b.active,
            sortDirections: ['descend', 'ascend']
        },
        {
            title: languagesCms.GROUP ,
            dataIndex: 'groups',
            key: 'groups',
            sorter: (a, b) => a.groups - b.groups,
            sortDirections: ['descend', 'ascend'],
            render: (user) => user.length > 0 && user.map(e => <div style={{marginBottom: 5}}>{e.name}</div>)
        },
        {
            title: 'Action',
            key: 'action',
            align: 'right',
            className: 'editColumn',
            width: 150,
            render: (text, record) => (
                <Space size="small">
                    <Button type="primary" icon={<FileExcelOutlined/>} onClick={() => getReport(record)}>
                        {!isSmall && languagesCms.REPORT }
                    </Button>
                    <Link to={"/activity2/attendees/" + record._id}>
                        <Button icon={<UserOutlined/>}>
                            {!isSmall && languagesCms.USERS}
                        </Button>
                    </Link>
                    <Link to={"/activity2/detail/" + activity + "/edit/" + record._id}>
                        <Button icon={<EditOutlined/>}>
                            {!isSmall && languagesCms.EDIT}
                        </Button>
                    </Link>
                    <Popconfirm
                        onConfirm={() => deleteRow(record._id)} title={languagesCms.CHECK_DELETE}
                        okText={languagesCms.OK_TEXT } cancelText={languagesCms.CANCEL_TEXT}>
                        <Button type="danger" icon={<DeleteOutlined/>}>
                            {!isSmall && languagesCms.DELETE }
                        </Button>
                    </Popconfirm>

                </Space>
            ),
        },
    ];

    return (
        <div>
            <div className="list-head">
                <div className="list-title">
                    <h1>{module ? module.name : ""}</h1>
                </div>
                <div className="list-buttons">
                    {!isSmall &&
                        <Button type="danger" icon={<DeleteOutlined/>} size="large" onClick={deleteSelectedRows}
                                style={{marginRight: "5px"}}>{languagesCms.DELETE_SELECTED }</Button>
                    }
                    <Link to={"/activity2/detail/" + activity + "/add"}>
                        <Button type="light" icon={<PlusOutlined/>} size={'large'}
                                style={{marginRight: "5px"}}>{!isSmall && languagesCms.ADD_NEW}</Button>
                    </Link>
                    <Link to="/activity2">
                        <Button type="light" icon={<LeftOutlined/>} size={'large'}
                                style={{marginRight: "5px"}}>{!isSmall && languagesCms.BACK}</Button>
                    </Link>
                </div>
            </div>

            <div className="table-wrap">
                <Table dataSource={data} columns={columns}
                       loading={{spinning: loading, indicator: <Loading3QuartersOutlined spin/>, size: "large"}}
                       onChange={handleTableChange} scroll={{x: 'auto'}}
                       pagination={{
                           total: totalCount
                       }}
                       rowSelection={{...rowSelection}}/>
            </div>

        </div>
    );
};


export default Activity2;
